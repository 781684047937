#klleon_chat {
    width: 100% !important;
    height: 84vh !important;
    /* background-color: blue; */
    justify-content: start;
    /* margin: 24px; */
    border-radius: 24px;
    /* overflow: hidden; */
    background-color: white;
   

}

#klleon_chat > flutter-view {
    display: block !important;
    overflow: visible !important;
    background-color: white;
}

.hide-scroll{
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

