/* Basic styles for tooltip */
.tooltip-box {
    display: none;
    position: absolute;
    background-color: #EBFBF4;
    color: #000;
    padding: 8px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
}

.relative:hover .tooltip-box {
    display: block;
    top: 30px;
    left: 80%;
    transform: translateX(-50%);
}
.tooltip {
    fill: transparent;
    pointer-events: none;
}
