.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  animation: rotate-container 20s linear infinite 1s;
  /* Starts after 1 sec delay */
}

/* SVG styling */
.circle {
  position: absolute;
  width: 623px;
  height: 623px;
  border-radius: 623px;
  background: rgba(52, 215, 149, 0.60);
  filter: blur(200px);
  opacity: var(--sds-size-stroke-border, 1);
}

/* Positioning the top circle */
.top {
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
}

/* Positioning the bottom circle */
.bottom {
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
}

/* Keyframes for rotating both circles around the center point */
@keyframes rotate-container {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
    /* Rotate anticlockwise */
  }
}