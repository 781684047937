@keyframes flipOut {
    0% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
    100% {
        transform: perspective(400px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes flipIn {
    0% {
        transform: perspective(400px) rotateX(-180deg);
        opacity: 0;
    }
    100% {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

.flip-out {
    animation: flipOut 0.5s forwards; /* Animation for flipping out */
}

.flip-in {
    animation: flipIn 0.5s forwards; /* Animation for flipping in */
}

