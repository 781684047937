.animated-button {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    /* width: 138px;  */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* padding-right: 20px; */
    /* padding-left: 20px; */
    /* border: 2px solid #34D795; */
    /* border-radius: 6px; */
    /* background-color: #ffffff; */
    /* color: #34D795; */
    /* font-size: 12px; */
    /* font-weight: 700; */
    cursor: pointer;
    overflow: hidden;
    /* position: relative; */
    transition: background-color 300ms;
  }
  
  .animated-button .icon,
  .animated-button .title {
    display: inline-block;
    transition: transform 300ms;
  }
  
  .animated-button:hover .icon {
    transform: translateX(70px);
  }
  
  .animated-button:hover .title {
    transform: translateX(-30px);
  }